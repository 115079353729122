<template>
	<div class="padding-top-10 contain">
		<top :bgWhite='true'></top>
		<div class="content bg-white padding-tb-140 flex align-center justify-center flex-direction">
			<img src="../assets/image/auth.png" class="width340 height340" >
			<div class=" margin-top-100 margin-bottom-40 flex align-center justify-center">
				<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
					@click="submitForm()">去认证</span>
			</div>
		</div>

		<bottom :bgWhite='true'></bottom>

	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'authen',
		components: {
			bottom,
			top
		},
		data() {
			return {
			
			}
		},
		mounted() {

		},
		methods: {
		
			submitForm() {
				this.$router.push({
					path: '/authen'
				})
				// this.$refs[formName].validate((valid) => {
				// 	if (valid) {
				// 		if (!this.ruleForm.card_img1) {
				// 			return this.$message.error('请上传身份证正面')
				// 		}
				// 		if (!this.ruleForm.card_img1) {
				// 			return this.$message.error('请上传身份证正面')
				// 		}
				// 		this.$router.push({
				// 			path: '/workUpload'
				// 		})
				// 	} else {
				// 		console.log('error submit!!');
				// 		return false;
				// 	}
				// });

			},
		}
	}
</script>

<style scoped>
	.contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.avatar {
		width: 200px;
		height: 126px;
		display: block;
	}

	/deep/.el-input {
		width: 320px;
		height: 36px;
	}
</style>
